<template>
  <div class="content">
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXFW:ZCFG:SFZJ')">
      <div class="wrap" v-if="!showDetail">
        <div class="left">
          <Tab :active="active" @change="change" :tabs="tabList" />
        </div>
        <div class="right">
          <div class="top-query">
            <span>{{ label }}</span>
            <div class="query-content position-absolute">
              <el-input clearable v-model="title" size="mini" placeholder="输入标题"></el-input>
            </div>
            <div class="button-content position-absolute">
              <el-button size="mini" type="primary" icon="el-icon-search" @click="query"> 查询 </el-button>
            </div>
          </div>
          <div class="page-content">
            <PageList :pageData="pageData" @checkDetail="checkDetail" @pageChange="pageChange" />
          </div>
        </div>
      </div>
      <PolicyDetail v-else :detailData="detailData" @back="back" />
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import { getPolicyData, getPolicyDetail } from '@iebdc/api/policy-service';
import Tab from './component/left-tab';
import PageList from './component/page-list';
import PolicyDetail from './component/policy-detail';
import hasConstruction from '@iebdc/mixin/hasConstruction';
export default {
  name: 'policyLaw',
  mixins: [hasConstruction],
  components: {
    Tab,
    PageList,
    PolicyDetail,
  },
  data() {
    return {
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '198px',
        imgHeight: '154px',
        marginTop: '-108px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      active: 0,
      label: '',
      tabList: [
        {
          label: '法律法规',
          value: 'flfg',
          img: require('@iebdc/assets/images/flfg.png'),
          activeImg: require('@iebdc/assets/images/flfg1.png'),
          permission: 'IEBDC:ZCFG:FLFG',
        },
        {
          label: '地方法规',
          value: 'dffg',
          img: require('@iebdc/assets/images/dffg.png'),
          activeImg: require('@iebdc/assets/images/dffg1.png'),
          permission: 'IEBDC:ZCFG:DFFG',
        },
        {
          label: '政策解读',
          value: 'zcjd',
          img: require('@iebdc/assets/images/zcjd.png'),
          activeImg: require('@iebdc/assets/images/zcjd1.png'),
          permission: 'IEBDC:ZCFG:ZCJD',
        },
      ],
      title: '', // 文章标题
      pageData: {},
      detailData: {},
      showDetail: false,
    };
  },
  created() {
    this.label = this.tabList[this.active].label;
    this.query();
  },
  methods: {
    successCallback(res, callback) {
      if (res && res.success) {
        callback(res.data);
      }
    },
    change(i) {
      this.active = i;
      this.label = this.tabList[i].label;
      this.getPolicyData();
    },
    query() {
      this.getPolicyData();
    },
    // 获取文章列表
    getPolicyData(current = 1) {
      getPolicyData({
        nCurrent: current - 1,
        articleTypeEnum: 'ZCFG',
        category: this.tabList[this.active].value,
        title: this.title,
        visible: true,
      }).then((res) => {
        this.successCallback(res, (data) => {
          this.pageData = data;
        });
      });
    },
    // 查看文章详细内容
    checkDetail(id) {
      getPolicyDetail({ id }).then((res) => {
        this.successCallback(res, (data) => {
          this.detailData = data;
          this.showDetail = true;
        });
      });
    },
    // 分页查询
    pageChange(currentPage) {
      this.getPolicyData(currentPage);
    },
    // 返回到文章列表页面
    back() {
      this.showDetail = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  .left {
    flex: 1;
  }
  .right {
    flex: 5;
    .top-query {
      position: relative;
      height: 42px;
      line-height: 42px;
      border-bottom: 1px solid #e7e7e7;
      span {
        display: inline-block;
        padding: 0 20px;
        height: 42px;
        font-size: 16px;
        color: #0168b7;
        border-bottom: 2px solid #0168b7;
      }
      .position-absolute {
        position: absolute;
        top: -5px;
      }
      .query-content {
        right: 90px;
      }
      .button-content {
        right: 0;
      }
    }
  }
}
</style>
