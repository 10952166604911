<template>
  <div class="tab-content">
    <div
      class="tab-item pointer"
      v-for="(item, i) in tabs"
      :key="i"
      :class="{ 'active-class': active === i }"
      @click="change(i, item.label)"
      v-if="$hasPermission(`${item.permission}`)"
    >
      <div v-show="active === i" class="trigon"></div>
      <img :src="getImgUrl(item, i)" />
      <p>{{ item.label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftTab',
  props: {
    active: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array
    }
  },
  methods: {
    change(i) {
      this.$emit('change', i);
    },
    getImgUrl(item, i) {
      return i === this.active ? item.activeImg : item.img;
    }
  }
};
</script>

<style scoped lang="scss">
$active-color: #167cc7;
.tab-content {
  width: 100%;
  .tab-item {
    position: relative;
    margin-top: 50px;
    padding-top: 5px;
    width: 110px;
    height: 90px;
    border: 1px solid #dddddd;
    border-radius: 10px;
    text-align: center;
    p {
      margin-top: 10px;
    }
    .trigon {
      position: absolute;
      top: 40px;
      right: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid $active-color;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }
}
.active-class {
  background: $active-color;
  color: white;
  border: none !important;
}
</style>
