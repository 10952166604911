import { render, staticRenderFns } from "./left-tab.vue?vue&type=template&id=306ce5e8&scoped=true&"
import script from "./left-tab.vue?vue&type=script&lang=js&"
export * from "./left-tab.vue?vue&type=script&lang=js&"
import style0 from "./left-tab.vue?vue&type=style&index=0&id=306ce5e8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306ce5e8",
  null
  
)

export default component.exports