// 权限混合
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['construction'])
  },
  methods: {
    // 权限判断
    hasConstruction(construction) {
      return this.construction.includes(construction);
    }
  }
};
