<template>
  <div>
    <div v-if="pageData.page && pageData.page.length > 0">
      <ul class="page-item" v-for="(item, i) in pageData.page" :key="i">
        <!-- <li class="el-icon-link"></li> -->
        <li class="m-l-10 pointer hover" @click="checkDetail(item.id)">
          {{ item.title }}
        </li>
        <span>{{ item.ctime | formatDate('yyyy-MM-dd') }}</span>
      </ul>
    </div>
    <div v-else class="no-data">暂无数据</div>
    <div class="pagination-content">
      <EPagination
        class="pagination"
        :total="pageData.recordCount"
        :pageSize="pageSize"
        :current="currentPage"
        @currentPageClick="currentPageClickHandle"
      />
    </div>
  </div>
</template>

<script>
import EPagination from '@/components/e-pagination/index';
export default {
  name: 'pageList',
  components: {
    EPagination,
  },
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
    };
  },
  methods: {
    checkDetail(id) {
      this.$emit('checkDetail', id);
    },
    currentPageClickHandle(val) {
      this.$emit('pageChange', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  padding-top: 10%;
  color: #909399;
}
.pagination-content {
  margin-top: 20px;
}
.m-l-10 {
  margin-left: 10px;
}
.page-item {
  position: relative;
  height: 40px;
  line-height: 40px;

  border-bottom: dashed 1px #cccccc;
  color: #3579e6;
  .hover {
    margin-right: 100px;
    width: 600px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: #4497eb;
    }
  }
  span {
    &:last-child {
      position: absolute;
      right: 0;
      top: 0;
      color: grey;
    }
  }
}
</style>
