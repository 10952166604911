<template>
  <article>
     <div class="text-left back">
      <el-link type="primary" @click="back" class="el-icon-back"> 返回 </el-link>
    </div>
    <h1>{{ detailData.subTitle }}</h1>
    <div class="address">
      发布时间：<span>{{ detailData.ctime | formatDate('yyyy-MM-dd') }}</span
      ><span v-show="detailData.mainWriter"
        >&nbsp;&nbsp;&nbsp;&nbsp; 作者：<span>{{ detailData.mainWriter }}</span></span
      ><span v-show="detailData.mainAuthor"
        >&nbsp;&nbsp;&nbsp;&nbsp; 来源：<span>{{ detailData.mainAuthor }}</span></span
      >
    </div>
    <div class="policy-content" v-html="detailData.contents"></div>
   
  </article>
</template>

<script>
export default {
  name: 'policyDetail',
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin: 20px 0;
  text-align: center;
  font-size: 32px;
  color: #333333;
}
.address {
  text-align: center;
  color: #333333;
  font-size: 13px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7d7d7;
}
.policy-content {
  margin: 20px -5px 0px 0px;
  line-height: 1.7;
  // /deep/ p:last-child {
  //   display: none;
  // }
  // /deep/ p:first-child {
  //   display: block;
  // }
  height: calc(100vh);
  overflow-y: auto;
}
.policy-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  }
  .policy-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ccc;
  }
  .policy-content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
  }
/deep/ img {
  width: 100%;
}
p {
  margin: 0 0 10px;
}
</style>
